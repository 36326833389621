@import url('https://fonts.googleapis.com/css2?family=Inter&family=Poppins&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
}

body {
    font-family: Inter, sans-serif;
}

.container {
    width: 550px;
    margin: 0 auto;
}

nav {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F55A5A;
    width: 550px;
    height: 55px;
    border: 1px solid #000000;
}

nav > p {
    color: #FFFFFF;
    margin: 10px 0 0 10px;
    font-size: 14.4608px;
    line-height: 18px;
    letter-spacing: -0.075em;
}

.card {
    display: flex;
    align-items: center;
    padding: 40px 0 0 40px;
    gap: 10px;
}

.image {
    width: 135px;
    height: 170px;
    border-radius: 5px;
}

.location {
    display: flex;
    align-items: center;
}

.loc--image {
    padding-right: 4px;
}

.loc {
    padding-right: 12px;
    letter-spacing: 0.17em;
    font-size: 12px;
    line-height: 12px;
    color: #2B283A;
}

.card--stats {
    padding-left: 20px;
    color: #2B283A;
}

.link {
    text-decoration-line: underline;
    color: #918E9B;
    font-size: 12px;
    line-height: 12px;
}

.title {
    margin: 7px 0;
    padding-bottom: 16px;
    font-size: 25px;
    font-weight: 700;
    line-height: 30px;
}

.desc {
    font-size: 12px;
    line-height: 150%;
    margin-top: 7px;
}